import React from "react";
import { Link } from "react-router-dom";
const HeaderMenu = () => {
  return (
    <div className="headerMenuNav">
      <nav className="navigation">
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">
            <Link
              to="/generative-ai"
              className="navigation-item-parent-nav-link"
            >
              Generative AI
            </Link>
          </div>
        </li>
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Products</div>
          <div className="submenu platform-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/inflow"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/inflow.svg"
                        alt="overview icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">inFlow</span>
                        <span className="submenu-navigation-subtitle">
                          Elevate your business with SaaS-driven conversational
                          AI
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/inflow-enterprise"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/inflow-enterprise.svg"
                        alt="integrations icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />

                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          inFlow Enterprise
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Custom LLM solutions for your infrastructure
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Solutions</div>

          <div className="submenu solutions-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <div className="submenu-box-title">By Function</div>
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/information-technology"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/it.svg"
                        alt="it icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">IT</span>
                        <span className="submenu-navigation-subtitle">
                          Enhance IT Efficiency and Effectiveness with AI
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/human-resources"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/social-care.svg"
                        alt="retail icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Human Resources
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Enrich and Elevate Employee Experience with AI
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/customer-support-automation"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/whatsapp.svg"
                        alt="csa icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Customer Support
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Smart Al for Superior Customer Support
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/procurement"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/goods.svg"
                        alt="retail icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Procurement
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Optimize Procurement with Intelligent AI
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/sales-and-marketing"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/legal.svg"
                        alt="retail icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Sales & Lead Generation
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Boost Sales with Al-Powered Solutions
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <div className="submenu-box-title">By Industry</div>

                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/retail"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/retail.svg"
                        alt="retail icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">Retail</span>
                        <span className="submenu-navigation-subtitle">
                          Conversational AI solution for Retail industry
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/restaurant"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/fnb.svg"
                        alt="fnb icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">F&B</span>
                        <span className="submenu-navigation-subtitle">
                          The dawn of the restaurant industry's future has
                          arrived.
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/healthcare"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/healthcare.svg"
                        alt="Healthcare icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Healthcare
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Seamless Communication for Better Healthcare
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/bank"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/bank.svg"
                        alt="bank icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">BFSI</span>
                        <span className="submenu-navigation-subtitle">
                          Conversational AI for Banking and Finance
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/other-industries"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/other-industries.svg"
                        alt="retail icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Other Industries
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Innovative AI solutions for every industry
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Resources</div>
          <div className="submenu resource-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/casestudy"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/case-study.svg"
                        alt="case study icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Case Studies
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Success stories and quotes from our customers
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/library"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/book.svg"
                        alt="library"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Library
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Latest Brochure & Guide essentials
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/blog"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/blog.svg"
                        alt="blog icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">Blogs</span>
                        <span className="submenu-navigation-subtitle">
                          Informative and inspiring articles from our team
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/webinars"
                      className="submenu-navigation-link"
                    >
                      <img
                        src="/images/icons/header-icons/webinar.svg"
                        alt="webinar icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Webinars
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Webinars on AI Shaping the Future of Business
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        {/* <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Partners</div>
          <div className="submenu partners-submenu">
            <div className="submenu-content">
              <div className="submenu-content">
                <div className="submenu-box">
                  <ul className="submenu-navigation">
                   
                  </ul>
                </div>
                <div className="submenu-box">
                  <ul className="submenu-navigation">
                    <li className="submenu-navigation-item">
                      <Link
                        to="/ourpartners"
                        className="submenu-navigation-link"
                      >
                        <img
                          src="/images/icons/header-icons/our-partners.svg"
                          alt="our partners icon"
                          width={40}
                          className="submenu-navigation-item-icon"
                        />
                        <div className="submenu-navigation-link-container">
                          <span className="submenu-navigation-title">
                            Our Partners
                          </span>
                          <span className="submenu-navigation-subtitle">
                            Find and connect with our trusted
                          </span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li> */}
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Company</div>
          <div className="submenu company-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link to="/about-us" className="submenu-navigation-link">
                      <img
                        src="/images/icons/header-icons/about.svg"
                        alt="about us icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          About Us
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Our Core Values & Beliefs that supports our Vision &
                          Mission
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link to="/career" className="submenu-navigation-link">
                      <img
                        src="/images/icons/header-icons/career.svg"
                        alt="career icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Careers
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Join our team to innovate & grow with us
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link to="/partners" className="submenu-navigation-link">
                      <img
                        src="/images/icons/header-icons/hand-shake-partners.svg"
                        alt="become a partner icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Partner with Us
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Explore our partnership programs and benifits
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link to="/news" className="submenu-navigation-link">
                      <img
                        src="/images/icons/header-icons/news.svg"
                        alt="news icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Events & News
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Provide up-to-date information on current events,
                          trends, and other news stories.
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link to="/contact" className="submenu-navigation-link">
                      <img
                        src="/images/icons/header-icons/contact.svg"
                        alt="contact us icon"
                        width={40}
                        className="submenu-navigation-item-icon"
                      />
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Contact
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Get in touch with us to enhance your Customer
                          Experience
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </nav>
    </div>
  );
};

export default HeaderMenu;
