import {
  MdInsights,
  MdOutlineDashboardCustomize,
  MdOutlineSecurity,
} from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";

export const salesAndMarketingContent = {
  banner: {
    primaryButton: {
      text: "Start your free trial",
      url: "/contact/#contactform",
    },
    description:
      "In today’s fast-paced sales environment, where efficiency and accuracy are essential, leveraging AI can streamline sales processes, enhance team performance, and drive growth, ultimately transforming sales operations and boosting productivity.",
    id: "1",
    imageURL: "/images/solutions/sales/banner.png",
    background: "/images/solutions/sales/banner.jpg",
    sectionTile: "",
    title: "Boost sales and generate leads with AI",
  },
  stats: {
    title: "Transform your sales with AI-driven solutions",
    description:
      "AI is revolutionizing sales operations, increasing leads, boosting productivity, and enhancing customer engagement, all while driving revenue growth.",
    content: [
      {
        id: "1",
        title: "Increase in the number of leads",
        count: 60,
      },
      {
        id: "2",
        title: "Increase in productivity",
        count: 56,
      },
      {
        id: "3",
        title: "Increase in revenue",
        count: 38,
      },
      {
        id: "4",
        title: "Increase in customer engagement",
        count: 73,
      },
    ],
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/sales/automate_crm.png",
      pointer: "/images/icons/pointer.svg",
      title: "Automate CRM updates",
      descriptionOne:
        "<strong>Seamless data extraction:</strong> Technology efficiently scans emAIls and messages to gather crucial prospect information.",
      descriptionTwo:
        "<strong>Automatic CRM management:</strong> Keep CRM records up-to-date automatically, minimizing the need for manual data entry.",
      descriptionThree:
        "<strong>Reduced errors:</strong> Automated processes cut down on manual entry errors, saving time and ensuring data accuracy.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/sales/empower_teams.png",
      pointer: "/images/icons/pointer.svg",
      title: "Empower sales teams",
      descriptionOne:
        "<strong>Real-time insights:</strong> Access up-to-the-minute analysis of customer behavior and sales forecasts.",
      descriptionTwo:
        "<strong>Informed decisions:</strong> Equip your sales team with actionable insights to tAIlor their strategies effectively.",
      descriptionThree:
        "<strong>Enhanced strategies:</strong> Utilize real-time data to refine sales tactics and improve deal closure rates.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/sales/elevate_conversations.png",
      pointer: "/images/icons/pointer.svg",
      title: "Elevate sales conversations",
      descriptionOne:
        "<strong>Real-time analysis:</strong> Conversations are transcribed and analyzed to extract valuable insights.",
      descriptionTwo:
        "<strong>Contextual recommendations:</strong> Receive suggestions for relevant case studies and resources based on conversation data.",
      descriptionThree:
        "<strong>Personalized pitches:</strong> Use insights to address customer challenges and customize your pitch effectively.",
    },
  ],
  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Unmatched security",
        tagline:
          "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <MdOutlineSecurity size={20} />,
      },
      {
        id: "2",
        title: "Seamless integration",
        tagline:
          "Easily integrates with existing systems, offering quick deployment and simplicity.",
        icon: <MdOutlineDashboardCustomize size={20} />,
      },
      {
        id: "3",
        title: "Data-driven insights",
        tagline:
          "Our AI solutions provide real-time analytics for informed, data-driven sales decisions.",
        icon: <MdInsights size={20} />,
      },
      {
        id: "4",
        title: "Comprehensive support",
        tagline:
          "Enhance communication with humanized, multi-channel, multilingual support for sales teams.",
        icon: <VscFeedback size={20} />,
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "How can AI enhance sales processes?",
      answer:
        "AI enhances sales processes by automating routine tasks, delivering personalized customer interactions, and providing critical insights for precise sales forecasting and strategic decision-making. By streamlining administrative duties and leveraging data, AI enables sales teams to focus on high-value activities and make informed decisions.",
    },
    {
      id: "2",
      question: "How do analytics and reporting impact sales?",
      answer:
        "Analytics and reporting play a crucial role in sales by providing valuable insights into performance, customer behavior, market trends, and revenue generation. They enable data-driven decision-making, uncover potential sales opportunities, and support the development of effective sales strategies, leading to improved performance and strategic planning.",
    },
    {
      id: "3",
      question: "What effects can AI have on sales?",
      answer:
        "Incorporating AI into sales processes significantly enhances productivity by automating repetitive tasks, improving customer engagement through personalized interactions, and offering data-driven insights. AI optimizes pipeline management and forecasting, leading to more precise decision-making. This transformative technology drives efficiency, effectiveness, and overall business growth by refining sales strategies and operations.",
    },
    {
      id: "4",
      question: "What is sales AI technology?",
      answer:
        "Sales AI technology refers to advanced systems designed to support and optimize sales activities. These technologies manage tasks such as lead research, appointment scheduling, and data reporting, allowing sales teams to focus on strategic initiatives. By handling routine functions and providing robust analytical support, sales AI technology enhances overall efficiency and effectiveness in sales operations.",
    },
  ],
  meta: {
    title: "AI Solutions for Sales and Marketing - iNextLabs",
    description:
      "Boost sales and marketing with AI assistants that automate routine tasks, streamline operations, and optimize customer interactions for success.",
    locale: "en_us",
    siteName: "inextlabs",
    image: "https://www.inextlabs.com/images/general/sales_resources.png",
    type: "website",
  },
};